import Vue from 'vue'
const user = {
  state: {
    user: {},
    token: localStorage.token || '',
  },
  mutations: {
    SET_TOKEN: (state: { token: any }, token: any) => {
      state.token = token
    },
    SET_USER: (state: { user: any }, user: any) => {
      state.user = user
    },
  },
  actions: {
    login({ commit }: any, user: any) {
      commit('SET_USER', user)
    },
    logout({ commit }: any) {
      localStorage.removeItem('token')
      commit('SET_TOKEN', '')
      commit('SET_USER', '')
    },
    getUserInfo({ commit }: any) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('users/info')
          .then((res: { data: unknown }) => {
            commit('SET_USER', res.data)
            resolve(res.data)
          })
          .catch((error: any) => {
            reject(error)
          })
      })
    },
  },
  getters: {
    user: (state: { user: any }) => state.user,
  },
}

export default user
