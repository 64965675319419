














































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      model: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    async login() {
      const { data: token } = await this.$http.post('login', this.model)
      localStorage.token = token
      /* eslint-disable */
      this.$router.push('/').catch(() => {})
      this.$message.success('登录成功')
    },
  },
})
