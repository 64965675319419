import Main from '@/views/Main.vue'
import mainRoutes from './mainRoutes'

export default {
  path: '/',
  component: Main,
  children: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        title: '管理面板',
      },
    },
    {
      path: '/post/list',
      component: () => import('@/views/post/List.vue'),
      meta: {
        title: '文章列表',
      },
    },
    {
      path: '/post/create',
      component: () => import('@/views/post/Edit.vue'),
      meta: {
        title: '创建文章',
      },
    },
    {
      path: '/post/edit/:id',
      component: () => import('@/views/post/Edit.vue'),
      props: true,
      meta: {
        title: '修改文章',
      },
    },
    {
      path: '/category/list',
      component: () => import('@/views/category/List.vue'),
      meta: {
        title: '分类列表',
      },
    },
    {
      path: '/category/create',
      component: () => import('@/views/category/Edit.vue'),
      meta: {
        title: '创建分类',
      },
    },
    {
      path: '/category/edit/:id',
      component: () => import('@/views/category/Edit.vue'),
      props: true,
      meta: {
        title: '修改分类',
      },
    },
    {
      path: '/user/list',
      component: () => import('@/views/user/List.vue'),
      meta: {
        title: '用户列表',
      },
    },
    {
      path: '/user/create',
      component: () => import('@/views/user/Edit.vue'),
      meta: {
        title: '创建用户',
      },
    },
    {
      path: '/user/edit/:id',
      component: () => import('@/views/user/Edit.vue'),
      props: true,
      meta: {
        title: '修改用户',
      },
    },
    {
      path: '/role/list',
      component: () => import('@/views/role/List.vue'),
      meta: {
        title: '角色列表',
      },
    },
    {
      path: '/role/create',
      component: () => import('@/views/role/Edit.vue'),
      meta: {
        title: '创建角色',
      },
    },
    {
      path: '/role/edit/:id',
      component: () => import('@/views/role/Edit.vue'),
      props: true,
      meta: {
        title: '修改角色',
      },
    },
    {
      path: '/menu/list',
      component: () => import('@/views/menu/List.vue'),
      meta: {
        title: '菜单列表',
      },
    },
    {
      path: '/menu/create',
      component: () => import('@/views/menu/Edit.vue'),
      meta: {
        title: '创建菜单',
      },
    },
    {
      path: '/menu/edit/:id',
      component: () => import('@/views/menu/Edit.vue'),
      props: true,
      meta: {
        title: '修改菜单',
      },
    },
    {
      path: '/permission/list',
      component: () => import('@/views/permission/List.vue'),
      meta: {
        title: '权限列表',
      },
    },
    {
      path: '/permission/create',
      component: () => import('@/views/permission/Edit.vue'),
      meta: {
        title: '创建权限',
      },
    },
    {
      path: '/permission/edit/:id',
      component: () => import('@/views/permission/Edit.vue'),
      props: true,
      meta: {
        title: '修改权限',
      },
    },
    {
      path: '/config/list',
      component: () => import('@/views/config/List.vue'),
      meta: {
        title: '代理列表',
      },
    },
    {
      path: '/config/create',
      component: () => import('@/views/config/Edit.vue'),
      meta: {
        title: '创建配置',
      },
    },
    {
      path: '/config/edit/:id',
      component: () => import('@/views/config/Edit.vue'),
      props: true,
      meta: {
        title: '修改配置',
      },
    },
    ...mainRoutes,
  ],
}
