import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/element'

import http from './utils/http'
import store from './store'

import './styles/index.scss'

Vue.config.productionTip = false
Vue.prototype.$http = http

Vue.mixin({
  computed: {
    uploadUrl() {
      return `${(this.$http as any).defaults.baseURL}/upload`
    },
    importChildrenUrl() {
      return `${(this.$http as any).defaults.baseURL}/children/import`
    },
    importWalletUrl() {
      return `${(this.$http as any).defaults.baseURL}/wallets/import`
    },
    importGoodsUrl() {
      return `${(this.$http as any).defaults.baseURL}/goods/import`
    },
  },
  methods: {
    getAuthHeaders() {
      return {
        Authorization: `Bearer ${localStorage.token || ''}`,
      }
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
