import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store'
import dynamicRoutes from './dynamicRoutes'

import Login from '@/views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isPublic: true,
      title: '用户登录',
    },
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    name: 'Page404',
    meta: {
      isPublic: true,
      title: '404',
    },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(async (to: any, from, next) => {
  NProgress.start()
  // 未登录跳转到登录页面
  if (!to.meta.isPublic && !localStorage.token) {
    return next('/login')
  }
  // 登录成功后不能再访问登录页面
  if (localStorage.token && to.path === '/login') {
    return next('/')
  }
  /**
   * 如果不是登录页面
   * 防止用户刷新浏览器，重新获取一次用户信息
   * 动态加载菜单路由
   */
  if (to.path !== '/login') {
    if (!Object.keys(store.getters.user).length) {
      let user: any = {}
      try {
        user = await store.dispatch('getUserInfo')
        const menus = user.role?.menus
        const tempMenus: any = []
        if (menus && menus.length) {
          menus.map((i: any) => {
            if (i.url) {
              tempMenus.push(i.url)
              if (i.crud) {
                tempMenus.push(`${i.url.replace('/list', '')}/create`)
                tempMenus.push(`${i.url.replace('/list', '')}/:id`)
                tempMenus.push(`${i.url.replace('/list', '')}/edit/:id`)
              }
            }
          })
        }

        if (user.username !== 'root') {
          dynamicRoutes.children = dynamicRoutes.children.filter((i) =>
            tempMenus.includes(i.path),
          )
        }
        if (dynamicRoutes.children.length) {
          router.addRoute({ ...dynamicRoutes })

          router.addRoute({ path: '*', redirect: '/404' })

          return next({ ...to, replace: true })
        }
      } catch (error) {
        console.log(error)
        await store.dispatch('logout')
        Message.error('请重新登录')
        next({ path: '/login' })
      }

      if (!user.username) {
        await store.dispatch('logout')
        Message.error('请重新登录')
        next({ path: '/login' })
      }
    }
  }

  setTitle(to)

  next()
})

const setTitle = (to: any) => {
  const title = 'Yx-Admin'
  document.title = to.meta.title ? `${title} ‧ ${to.meta.title}` : title
}

router.afterEach(() => NProgress.done())

export default router
