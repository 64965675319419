export default [
  {
    path: '/proxy/list',
    component: () => import('@/views/proxy/List.vue'),
    meta: {
      title: '代理列表',
    },
  },
  {
    path: '/proxy/create',
    component: () => import('@/views/proxy/Edit.vue'),
    meta: {
      title: '创建代理',
    },
  },
  {
    path: '/proxy/edit/:id',
    component: () => import('@/views/proxy/Edit.vue'),
    props: true,
    meta: {
      title: '修改代理',
    },
  },

  {
    path: '/good/list',
    component: () => import('@/views/good/List.vue'),
    meta: {
      title: '商品列表',
    },
  },
  {
    path: '/good/create',
    component: () => import('@/views/good/Edit.vue'),
    meta: {
      title: '创建商品',
    },
  },
  {
    path: '/good/edit/:id',
    component: () => import('@/views/good/Edit.vue'),
    props: true,
    meta: {
      title: '修改商品',
    },
  },

  {
    path: '/rate/list',
    component: () => import('@/views/rate/List.vue'),
    meta: {
      title: '汇率列表',
    },
  },
  {
    path: '/rate/create',
    component: () => import('@/views/rate/Edit.vue'),
    meta: {
      title: '创建汇率',
    },
  },
  {
    path: '/rate/edit/:id',
    component: () => import('@/views/rate/Edit.vue'),
    props: true,
    meta: {
      title: '修改汇率',
    },
  },

  {
    path: '/hot/list',
    component: () => import('@/views/hot/List.vue'),
    meta: {
      title: '热门搜索列表',
    },
  },
  {
    path: '/hot/create',
    component: () => import('@/views/hot/Edit.vue'),
    meta: {
      title: '创建热门搜索',
    },
  },
  {
    path: '/hot/edit/:id',
    component: () => import('@/views/hot/Edit.vue'),
    props: true,
    meta: {
      title: '修改热门搜索',
    },
  },

  {
    path: '/crontab/list',
    component: () => import('@/views/crontab/List.vue'),
    meta: {
      title: '定时任务列表',
    },
  },
  {
    path: '/crontab/create',
    component: () => import('@/views/crontab/Edit.vue'),
    meta: {
      title: '创建定时任务',
    },
  },
  {
    path: '/crontab/edit/:id',
    component: () => import('@/views/crontab/Edit.vue'),
    props: true,
    meta: {
      title: '修改定时任务',
    },
  },

  {
    path: '/sell/list',
    component: () => import('@/views/sell/List.vue'),
    meta: {
      title: '热卖列表',
    },
  },
  {
    path: '/sell/create',
    component: () => import('@/views/sell/Edit.vue'),
    meta: {
      title: '创建热卖',
    },
  },
  {
    path: '/sell/edit/:id',
    component: () => import('@/views/sell/Edit.vue'),
    props: true,
    meta: {
      title: '修改热卖',
    },
  },
]
