export function data2tree(data: any, parent_id = ''): any {
  const tree: any[] = []
  let temp
  data.forEach((item: any) => {
    if (item.parent_id == parent_id || (!item.parent_id && !parent_id)) {
      const obj = item
      temp = data2tree(data, item.id)
      if (temp.length > 0) {
        obj.children = temp
      }
      tree.push(obj)
    }
  })
  return tree
}
