const app = {
  state: {
    cachedViews: [],
  },
  mutations: {
    ADD_VIEW: (state: { cachedViews: any[] }, view: any) => {
      state.cachedViews.push(view)
    },
    DEL_VIEW: (state: { cachedViews: any[] }, route: { path: any }) => {
      state.cachedViews = state.cachedViews.filter(
        (i: { path: any }) => i.path !== route.path,
      )
    },
    DEL_OTHER_VIEWS: (state: { cachedViews: any[] }, route: { path: any }) => {
      state.cachedViews = state.cachedViews.filter(
        (i: { path: any }) => i.path === route.path,
      )
    },
    DEL_ALL_VIEWS: (state: { cachedViews: never[] }) =>
      (state.cachedViews = []),
  },
  actions: {
    /**
     * view shape
     * {
     *  title: '',
     *  url: '',
     * }
     */
    addView({ commit }: any, route: { path: any }) {
      const isCached = (this as any).state.app.cachedViews.some(
        (i: { path: any }) => i.path === route.path,
      )
      if (!isCached) {
        commit('ADD_VIEW', route)
      }
    },
    delView({ commit }: any, route: any) {
      commit('DEL_VIEW', route)
    },
    delOthersViews({ commit }: any, route: any) {
      commit('DEL_OTHER_VIEWS', route)
    },
    delAllViews({ commit }: any) {
      commit('DEL_ALL_VIEWS')
    },
  },
  getters: {
    cachedViews: (state: { cachedViews: any }) => state.cachedViews,
  },
}

export default app
